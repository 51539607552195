import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {usersApi} from "./apis/users";
import {adminApi} from "./apis/admins";
import {productsApi} from "./apis/products";
import {authReducer} from "./slices/authSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

export const store = configureStore({
    reducer: {
        [usersApi.reducerPath]: usersApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(usersApi.middleware)
            .concat(adminApi.middleware)
            .concat(productsApi.middleware);
    }
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Customized hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {
    useGetUsersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useChargeUserMutation,
    useChangePanelMutation,
    useEnableUserMutation,
    useDisableUserMutation,
    useUserSubscriptionQuery,
} from './apis/users';
export type {User, UserSubscription} from './apis/users';

export {
    useLoginMutation,
    useProfileQuery,
} from './apis/admins';
export type {LoginRes, Admin} from './apis/admins';

export {
    useGetProductsQuery,
    useCreateProductMutation,
} from './apis/products';
export type {Product} from './apis/products';

export {
    setCredentials,
    logout,
} from './slices/authSlice';
export type {AuthState} from './slices/authSlice';
