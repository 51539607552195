import React from 'react';
import {useParams} from 'react-router-dom';
import {Subscription} from '../components/users/Subscription';
import {NotFound} from '../components/NotFound';
import {useUserSubscriptionQuery} from "../store";

export function SubscriptionPage() {
    const {token} = useParams<{ token: string }>();
    const {data: subscription, error, isFetching} = useUserSubscriptionQuery({userToken: token || ''});

    if (isFetching) {
        return <div>Loading...</div>;
    }

    if (error || !subscription) {
        return <NotFound/>;
    }

    return <Subscription subscription={subscription}/>;
}
