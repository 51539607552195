import {Link} from 'react-router-dom';
import {useGetUsersQuery} from "../../store";

export function UsersList() {
    const {data: users, isFetching, isError} = useGetUsersQuery();

    if (isFetching) {
        return <div>Loading...</div>;
    }

    if (isError || !users) {
        return <div>Error fetching users.</div>;
    }

    const renderedUsers = users.map(({id, email, name, token, expire, amount, used}) => {
        const daysLeft = Math.max(0, Math.floor((new Date(expire).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)));

        return (
            <tr key={id}>
                <td>{id}</td>
                <td>{email}</td>
                <td>{name}</td>
                <td className="text-center">{Math.round((amount - used) * 100) / 100} (GB)</td>
                <td className="text-center">{daysLeft}</td>
                <td>
                    <Link to={`/users/sub/${token}`} target="_blank" rel="noopener noreferrer"
                          className="text-decoration-none">
                        View Subscription
                    </Link>
                </td>
                <td>
                    <Link to={`/users/${id}`} className="text-decoration-none fw-bold">
                        Manage
                    </Link>
                </td>
            </tr>
        );
    });

    return (
        <div className="table-responsive">
            <table
                className="table table-striped table-hover bg-light table-bordered rounded-3 overflow-hidden shadow small"
                id="users_table"
            >
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Amount Left</th>
                        <th>Days Left</th>
                        <th>Sub Link</th>
                        <th>Manage User</th>
                    </tr>
                </thead>
                <tbody>
                    {renderedUsers}
                </tbody>
            </table>
        </div>
    );
}
