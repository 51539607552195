import {WelcomePanel} from '../components/WelcomePanel';
import {FormEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {setCredentials, useAppDispatch, useAppSelector, useLoginMutation, useProfileQuery} from "../store";

export function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.auth.token);
    const [login, {isLoading, error}] = useLoginMutation();
    const {data: admin} = useProfileQuery(token || '', {skip: !token});

    useEffect(() => {
        if (admin) {
            dispatch(setCredentials({token, admin}));
            navigate('/dashboard');
        }
    }, [admin, token, dispatch, navigate]);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const {token} = await login({username, password}).unwrap();

            dispatch(setCredentials({token, admin: null}));
        } catch (err: any) {
            console.error('Login failed: ', err);
        }
    };

    return (
        <div>
            <WelcomePanel/>
            <div className="row justify-content-center mt-5 mx-2">
                <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                    <div className="card shadow" id="login_card">
                        <div className="card-body">
                            <div className="card-title">
                                <h3>Login</h3>
                                <div className="card-text">
                                    <p className="small text-muted">Login with your username &amp; password</p>
                                    {error && (
                                        <div className="alert alert-danger" role="alert" id="login_alert">
                                            {(error as any).data?.message || 'Login failed'}
                                        </div>
                                    )}
                                    <form id="login_form" onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <input
                                                type="email" className="form-control form-control mt-2"
                                                placeholder="Email" required name="email"
                                                value={username}
                                                onChange={e => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="password" className="form-control form-control mt-2"
                                                placeholder="Password" required name="password"
                                                value={password}
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 d-grid">
                                            <button type="submit"
                                                    className="btn btn-success w-100" name="login_btn"
                                                    disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status"
                                                          aria-hidden="true"></span>
                                                ) : (
                                                    'Login'
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
