import React, {useState} from 'react';
import {UserSubscription} from '../../store';
import {Overlay, Tooltip} from 'react-bootstrap';
import moment from 'jalali-moment';
import {DailyUsageChart} from './DailyUsage';

interface SubscriptionProps {
    subscription: UserSubscription;
}

const CircleProgress = (
    {
        value,
        max,
        labelTop,
        labelBottom,
        size = 100,
    }: {
        value: number;
        max: number;
        labelTop: string;
        labelBottom: string;
        size?: number;
    }) => {
    const radius = size / 2;
    const strokeWidth = 10;
    const normalizedRadius = radius - strokeWidth * 0.5;
    const circumference = normalizedRadius * 2 * Math.PI;
    const progress = (value / max) * circumference;

    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            <circle
                cx={radius}
                cy={radius}
                r={normalizedRadius}
                fill="transparent"
                stroke="#e6e6e6"
                strokeWidth={strokeWidth}
            />
            <circle
                cx={radius}
                cy={radius}
                r={normalizedRadius}
                fill="transparent"
                stroke="#4db8ff"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={circumference - progress}
                strokeLinecap="round"
                transform={`rotate(-90 ${radius} ${radius})`}
            />
            <text x="50%" y="40%" textAnchor="middle" fontSize="16" fill="#333">
                {labelTop}
            </text>
            <line
                x1="55"
                y1={size / 2}
                x2={size - 55}
                y2={size / 2}
                stroke="#333"
                strokeWidth="1"
                // strokeDasharray="5,5"
            />
            <text x="50%" y="65%" textAnchor="middle" fontSize="16" fill="#333">
                {labelBottom}
            </text>
        </svg>
    );
};

export function Subscription({subscription}: SubscriptionProps) {
    let {
        email,
        name,
        panelName,
        dailyUsage,
        amount,
        expire,
        used,
        onlineAt,
        status,
        links,
    } = subscription;

    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [tooltipTarget, setTooltipTarget] = useState<HTMLElement | null>(null);

    // Date formatting helper
    const formatDate = (date: Date, format: string = 'YYYY/MM/DD') =>
        date ? moment(date).locale('fa').format(format) : 'N/A';

    const expireDate = formatDate(expire);
    const onlineDate = onlineAt ? formatDate(onlineAt, 'YYYY/MM/DD HH:mm') : 'Offline';

    // Fix format of numbers, only display until two digits
    const formatTraffic = (value: number) => Math.round(value * 100) / 100;
    amount = formatTraffic(amount);
    used = formatTraffic(used);

    // Calculate percentage used and days left
    const circleSize = 200;
    const daysLeft = Math.max(0, Math.floor((new Date(expire).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1);
    const totalDays = 30;

    // Helper function to extract the display name
    const getDisplayName = (link: string, index: number) => {
        if (link.startsWith('vmess://')) {
            try {
                // Decode the Base64 part of the vmess link
                const base64String = link.replace('vmess://', '');
                const decodedString = atob(base64String);
                const json = JSON.parse(decodedString);

                // Extract the 'ps' property, or use a default if not present
                return json.ps || `Link ${index + 1}`;
            } catch (error) {
                return `Link ${index + 1}`;
            }
        }

        // For other protocols, extract the name after '#'
        return decodeURIComponent(link.split('#')[1] || `Link ${index + 1}`);
    };

    // Copy to clipboard functions with pop-up notification
    const copyToClipboard = (text: string, target: HTMLElement) => {
        navigator.clipboard.writeText(text).then();
        setTooltipText('!لینک کپی شد');
        setTooltipTarget(target);
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000);
    };

    const copyAllLinks = (target: HTMLElement) => {
        navigator.clipboard.writeText(links.join('\n')).then();
        setTooltipText('All links copied!');
        setTooltipTarget(target);
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000);
    };

    return (
        <div className="container mt-3">
            <div className="row mb-3">
                <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                    <div className="card shadow-lg">
                        <div className="card-header text-center bg-primary text-white">
                            <h3>اطلاعات کاربری</h3>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-bordered text-center">
                                    <tbody>
                                        <tr>
                                            <td>{name}</td>
                                            <th className="text-muted text-end" dir="rtl">نام:</th>
                                        </tr>
                                        <tr>
                                            <td>{email}</td>
                                            <th className="text-muted text-end" dir="rtl">ایمیل:</th>
                                        </tr>
                                        <tr>
                                            <td>{panelName}</td>
                                            <th className="text-muted text-end" dir="rtl">نام پنل:</th>
                                        </tr>
                                        <tr>
                                            <td>{onlineDate}</td>
                                            <th className="text-muted text-end" dir="rtl">آخرین زمان اتصال:</th>
                                        </tr>
                                        <tr>
                                            <td>{expireDate}</td>
                                            <th className="text-muted text-end" dir="rtl">تاریخ انقضا:</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span
                                                    className={`badge ${status === 'active' ? 'bg-success' : 'bg-danger'} mt-2 px-2 py-2`}>
                                                    {status.charAt(0).toUpperCase() + status.slice(1)}
                                                </span>
                                            </td>
                                            <th className="text-muted text-end" dir="rtl">وضعیت حساب:</th>
                                        </tr>
                                        <tr>
                                            <td>{amount} GB</td>
                                            <th className="text-muted text-end" dir="rtl">مقدار حجم:</th>
                                        </tr>
                                        <tr>
                                            <td>{used} GB</td>
                                            <th className="text-muted text-end" dir="rtl">مقدار استفاده شده:</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-sm-10 col-md-8 col-lg-6 mt-3 mt-md-0">
                    <div className="card shadow-lg">
                        <div className="card-header text-center bg-primary text-white">
                            <h3>اطلاعات مصرف</h3>
                        </div>
                        <div className="card-body">
                            {/* Circular Progress Bar for Data Usage */}
                            <div className="row text-center justify-content-center mt-4">
                                <div className="col-12 col-md-6 col-lg-5">
                                    <CircleProgress
                                        value={amount - used}
                                        max={amount}
                                        labelTop={`${formatTraffic(amount - used)} GB`}
                                        labelBottom={`${amount} GB`}
                                        size={circleSize}
                                    />
                                    <h6 className="text-muted mt-2">ترافیک باقی مانده</h6>
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mt-3 mt-md-0">
                                    <CircleProgress
                                        value={daysLeft}
                                        max={totalDays}
                                        labelTop={`${daysLeft} Days`}
                                        labelBottom={`${totalDays} Days`}
                                        size={circleSize}
                                    />
                                    <h6 className="text-muted mt-2">روز های باقی مانده</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <div className="card shadow-lg" dir="rtl">
                        <div className="card-header text-center bg-primary text-white">
                            <h3>اتصال به سرویس</h3>
                        </div>
                        <div className="card-body">
                            {/* Links Section */}
                            <div className="row mt-4">
                                <div className="col-12">
                                    <h6 className="text-muted">لینک های اتصال:</h6>
                                    <div className="list-group" dir="ltr">
                                        {links.map((link, index) => {
                                            const displayName = getDisplayName(link, index);

                                            return (
                                                <div key={index}
                                                     className="list-group-item d-flex justify-content-between align-items-center">
                                                    <span
                                                        className="text-dark-emphasis fw-semibold">{displayName}</span>
                                                    <button
                                                        className="btn btn-sm btn-outline-secondary"
                                                        onClick={(e) => copyToClipboard(link, e.currentTarget)}
                                                    >
                                                        کپی لینک
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="text-center mt-3">
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) => copyAllLinks(e.currentTarget)}
                                        >
                                            کپی تمامی لینک ها
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-muted text-center">
                            <small>Thank you for using our service</small>
                        </div>
                    </div>
                </div>
            </div>

            {dailyUsage.length !== 0 &&
                <div className="row mb-3">
                    <div className="col">
                        <div className="card shadow-lg" dir="rtl">
                            <div className="card-header text-center bg-primary text-white">
                                <h3>نمودار مصرف روزانه</h3>
                            </div>
                            <div className="card-body">
                                {/* Links Section */}
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <DailyUsageChart dailyUsage={dailyUsage}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* Tooltip Overlay for Copy Notifications */}
            <Overlay target={tooltipTarget} show={showTooltip} placement="top">
                {(props) => (
                    <Tooltip id="copy-tooltip" {...props}>
                        {tooltipText}
                    </Tooltip>
                )}
            </Overlay>
        </div>
    );
}
