import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Admin} from "../apis/admins";

export interface AuthState {
    admin: Admin | null;
    token: string | null;
}

const storedToken = localStorage.getItem("token");
const storedAdmin = localStorage.getItem("admin");

const initialState: AuthState = {
    admin: storedAdmin ? JSON.parse(storedAdmin) : null,
    token: storedToken,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<AuthState>) => {
            state.token = action.payload.token;
            state.admin = action.payload.admin;

            localStorage.setItem("token", action.payload.token || '');
            localStorage.setItem("admin", JSON.stringify(action.payload.admin || null));
        },
        logout: (state) => {
            state.token = null;
            state.admin = null;

            localStorage.removeItem("token");
            localStorage.removeItem("admin");
        },
    },
});

export const {
    setCredentials,
    logout,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
