import {FormEvent, useState} from 'react';
import {useDisableUserMutation, useEnableUserMutation, User, useUpdateUserMutation} from "../../store";

interface UserEditProps {
    user: User;
}

export function UserEdit({user}: UserEditProps) {
    const {id: userId} = user;

    const [updateUser, {isLoading: isUpdating}] = useUpdateUserMutation();
    const [enableUser, {isLoading: isEnabling}] = useEnableUserMutation();
    const [disableUser, {isLoading: isDisabling}] = useDisableUserMutation();

    const [email, setEmail] = useState(user.email);
    const [name, setName] = useState(user.name);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setError(null);

        try {
            await updateUser({userId, name, email}).unwrap();
        } catch (err: any) {
            setError(err?.data?.message || 'User update failed');
        }
    };

    const handleEnable = async () => {
        try {
            await enableUser({userId}).unwrap();
        } catch (err: any) {
            setError(err?.data?.message || 'User enabling failed');
        }
    };

    const handleDisable = async () => {
        try {
            await disableUser({userId}).unwrap();
        } catch (err: any) {
            setError(err?.data?.message || 'User disabling failed');
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    <h3>Edit User Details</h3>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    {error &&
                        <div className="alert alert-danger" role="alert" id={`edit_user_${userId}_alert`}>
                            {error}
                        </div>
                    }
                    <form onSubmit={handleSubmit} id={`edit_user_${userId}_form`}>
                        <div className="mb-3">
                            <input
                                type="email" required name="email" placeholder="Email"
                                className="form-control form-control-sm"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text" required name="name" placeholder="Name"
                                className="form-control form-control-sm"
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn-sm btn-success w-100"
                                    name="update_user_btn"
                                    disabled={isUpdating}
                            >
                                {isUpdating ? (
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                ) : (
                                    'Update User'
                                )}
                            </button>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary me-2"
                                onClick={handleEnable}
                                disabled={isEnabling}
                            >
                                {isEnabling ? (
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                ) : (
                                    'Enable User'
                                )}
                            </button>
                            <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={handleDisable}
                                disabled={isDisabling}
                            >
                                {isDisabling ? (
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                ) : (
                                    'Disable User'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
