import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {CONFIG} from "../../config";

export interface Product {
    id: string;
    name: string;
    amount: number;
    days: number;
}

export const productsApi = createApi({
    reducerPath: "products",
    baseQuery: fetchBaseQuery({
        baseUrl: CONFIG.backendPrefix,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as any).auth.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getProducts: builder.query<Product[], void>({
            query: () => ({
                url: "/products",
                method: "GET",
            }),
        }),
        createProduct: builder.mutation<Product, { name: string; days: number; amount: number }>({
            query: ({name, days, amount}) => ({
                url: "/products",
                method: "POST",
                body: {name, days, amount},
            }),
        }),
    }),
});

export const {
    useGetProductsQuery,
    useCreateProductMutation,
} = productsApi;
