import {User} from '../../store';
import moment from 'moment';

interface UserDetailsProps {
    user: User;
}

export function UserDetails({user}: UserDetailsProps) {
    const {id: userId, email, name, panelName, adminId, lastEmergencyChargeDate, token, amount, expire, used} = user;
    const formatDate = (date: string) => {
        return moment(date).format('YYYY/MM/DD');
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-center align-items-center">
                <h3>User Details</h3>
            </div>
            <div className="table-responsive mt-2 ">
                <table className="table table-striped table-bordered table-hover rounded-2 overflow-hidden small"
                       id={`user_details_${userId}_table`}
                >
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <td>{userId}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{email}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{name}</td>
                        </tr>
                        <tr>
                            <th>Panel Name</th>
                            <td>{panelName}</td>
                        </tr>
                        <tr>
                            <th>Admin Id</th>
                            <td>{adminId}</td>
                        </tr>
                        <tr>
                            <th>Token</th>
                            <td>{token}</td>
                        </tr>
                        <tr>
                            <th>Last Emergency Charge</th>
                            <td>{formatDate(lastEmergencyChargeDate.toString())}</td>
                        </tr>
                        <tr>
                            <th>Expiration Date</th>
                            <td>{formatDate(expire.toString())}</td>
                        </tr>
                        <tr>
                            <th>Amount (GB)</th>
                            <td>{Math.round(amount * 100) / 100}</td>
                        </tr>
                        <tr>
                            <th>Used (GB)</th>
                            <td>{Math.round(used * 100) / 100}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
