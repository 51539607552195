import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {logout, useAppDispatch, useAppSelector} from "../store";

export function Navbar() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const admin = useAppSelector(state => state.auth.admin);

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    const linkItems = [
        {
            label: 'Profile',
            path: '/profile',
        },
        {
            label: 'Users',
            path: '/users',
        },
    ];

    const renderedLinks = linkItems.map((item) => {
        return (
            <li className="nav-item" key={item.label}>
                <NavLink
                    to={item.path}
                    className={({isActive}) =>
                        isActive ? 'nav-link active' : 'nav-link'
                    }
                >
                    {item.label}
                </NavLink>
            </li>
        );
    });

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <NavLink
                    to={'/dashboard'}
                    className="navbar-brand text-info"
                >
                    Hi, {admin?.name}!
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {renderedLinks}
                    </ul>
                    <form className="d-flex align-items-center mt-2 mt-lg-0" role="search">
                        <div className="input-group">
                            <label htmlFor="remaining-amount"
                                   className="form-label text-white mt-2 mx-2 d-none d-lg-block">
                                Remaining Charge:
                            </label>
                            <input type="text" className="form-control form-control-sm  text-center fw-bold fs-6 p-0"
                                   readOnly
                                   id="remaining-amount"
                                   disabled
                                   value={`${Math.round(admin?.amount! * 100) / 100} GB`}/>
                        </div>
                    </form>
                    <div className="d-grid">
                        <button
                            className="btn btn-sm btn-warning mt-2 mx-lg-2 mt-lg-0"
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}
