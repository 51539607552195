import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {CONFIG} from "../../config";

export interface User {
    id: string;
    email: string;
    name: string;
    panelName: string;
    adminId: string;
    lastEmergencyChargeDate: Date;
    token: string;
    dailyUsage: { date: Date; usage: number }[];
    amount: number;
    expire: Date;
    used: number;
}

export interface UserSubscription {
    email: string;
    name: string;
    panelName: string;
    lastEmergencyChargeDate: Date;
    token: string;
    dailyUsage: { date: Date; usage: number }[];
    amount: number;
    expire: Date;
    used: number;
    onlineAt: Date | null;
    status: string;
    links: string[];
}

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: CONFIG.backendPrefix,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as any).auth.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Users", "UserItem"],
    endpoints: (builder) => ({
        getUsers: builder.query<User[], void>({
            query: () => "/users",
            providesTags: ["Users"],
        }),

        getUser: builder.query<User, string>({
            query: (userId) => `/users/${userId}`,
            providesTags: (result, error, userId) => [{type: "UserItem", id: userId}],
        }),

        createUser: builder.mutation<User, {
            email: string;
            name: string;
            panelName: string;
            productName: string;
        }>({
            query: ({email, name, panelName, productName}) => ({
                url: "/users",
                method: "POST",
                body: {email, name, panelName, productName},
            }),
            invalidatesTags: (result, error) => (result ? ["Users"] : []),
        }),

        updateUser: builder.mutation<User, { userId: string; name?: string; email?: string }>({
            query: ({userId, name, email}) => ({
                url: `/users/${userId}`,
                method: "PUT",
                body: {name, email},
            }),
            invalidatesTags: (result, error, {userId}) => (result ? [{type: "UserItem", id: userId}] : []),
        }),

        chargeUser: builder.mutation<User, { userId: string; productName: string }>({
            query: ({userId, productName}) => ({
                url: `/users/${userId}/charge`,
                method: "PUT",
                body: {productName},
            }),
            invalidatesTags: (result, error, {userId}) => (result ? [{type: "UserItem", id: userId}] : []),
        }),

        changePanel: builder.mutation<User, { userId: string; panelName: string }>({
            query: ({userId, panelName}) => ({
                url: `/users/${userId}/change-panel`,
                method: "PUT",
                body: {panelName},
            }),
            invalidatesTags: (result, error, {userId}) => (result ? [{type: "UserItem", id: userId}] : []),
        }),

        enableUser: builder.mutation<{ message: string; status: string }, { userId: string }>({
            query: ({userId}) => ({
                url: `/users/${userId}/enable`,
                method: "PUT",
            }),
            invalidatesTags: (result, error, {userId}) => (result ? [{type: "UserItem", id: userId}] : []),
        }),

        disableUser: builder.mutation<{ message: string; status: string }, { userId: string }>({
            query: ({userId}) => ({
                url: `/users/${userId}/disable`,
                method: "PUT",
            }),
            invalidatesTags: (result, error, {userId}) => (result ? [{type: "UserItem", id: userId}] : []),
        }),

        userSubscription: builder.query<UserSubscription, { userToken: string }>({
            query: ({userToken}) => `/users/sub/${userToken}`,
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useChargeUserMutation,
    useChangePanelMutation,
    useEnableUserMutation,
    useDisableUserMutation,
    useUserSubscriptionQuery,
} = usersApi;
