import {Link, useNavigate, useParams} from 'react-router-dom';
import {useGetUserQuery} from '../../store';
import {NotFound} from "../NotFound";
import React from "react";
import {UserDetails} from "./UserDetails";
import {UserEdit} from "./UserEdit";
import {UserCharge} from "./UserCharge";
import {UserChangePanel} from "./UserChangePanel";

export function UserItem() {
    const {userId} = useParams<{ userId: string }>();
    const navigate = useNavigate();
    const {data: user, error, isFetching} = useGetUserQuery(userId || '');

    if (isFetching) {
        return <p>Loading user details...</p>;
    }

    if (error || !user) {
        return <NotFound/>;
    }

    return (
        <div className="container p-3">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <Link to={`/users/sub/${user.token}`} target="_blank" rel="noopener noreferrer"
                          className="text-decoration-none">
                        <h2>View Subscription</h2>
                    </Link>
                    <button
                        onClick={() => navigate('/users')}
                        className="btn btn-secondary btn-sm ms-3">
                        Back to Users
                    </button>
                </div>
            </div>
            <hr/>
            <div className="row mt-3">
                <div className="col-12">
                    <UserDetails user={user}/>
                </div>
            </div>
            <hr/>
            <div className="row g-2">
                <div className="col-12 col-md-4">
                    <UserEdit user={user}/>
                </div>

                <div className="d-md-none">
                    <hr/>
                </div>

                <div className="col-12 col-md-4">
                    <UserCharge user={user}/>
                </div>

                <div className="d-md-none">
                    <hr/>
                </div>

                <div className="col-12 col-md-4">
                    <UserChangePanel user={user}/>
                </div>
            </div>
        </div>
    );
}

