import React from 'react';
import {Navbar} from './components/Navbar';
import {LoginPage} from './pages/LoginPage';
import {DashboardPage} from './pages/DashboardPage';
import {UsersPage} from './pages/UsersPage';
import {NotFound} from './components/NotFound';
import {Route, Routes, useLocation} from 'react-router-dom';
import {SubscriptionPage} from './pages/SubscriptionPage';
import {AdminProfile} from './components/admin/AdminProfile';
import {ProtectedRoute} from "./components/ProtectedRoute";
import {UserItem} from "./components/users/UserItem";

export function App() {
    const location = useLocation();

    const showNavbarPaths = ['/dashboard', '/users', '/profile'];
    const shouldShowNavbar = showNavbarPaths.includes(location.pathname);

    return (
        <>
            {shouldShowNavbar && <Navbar/>}
            <Routes>
                <Route path="/" element={<LoginPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/404" element={<NotFound/>}/>

                {/* Protected Routes */}
                <Route path="/dashboard" element={
                    <ProtectedRoute>
                        <DashboardPage/>
                    </ProtectedRoute>
                }
                />
                <Route path="/users" element={
                    <ProtectedRoute>
                        <UsersPage/>
                    </ProtectedRoute>
                }
                />
                <Route path="/users/:userId" element={
                    <ProtectedRoute>
                        <UserItem/>
                    </ProtectedRoute>
                }
                />
                <Route path="/profile" element={
                    <ProtectedRoute>
                        <AdminProfile/>
                    </ProtectedRoute>
                }
                />

                {/* Dynamic subscription route */}
                <Route path="/users/sub/:token" element={<SubscriptionPage/>}/>

                {/* Catch-all 404 route */}
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    );
}
