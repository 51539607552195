import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {CONFIG} from "../../config";

export interface LoginRes {
    token: string;
    superAdmin: boolean;
}

export interface Admin {
    id: string;
    username: string;
    name: string;
    status: boolean;
    superAdmin: boolean;
    amount: number;
}

export const adminApi = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: CONFIG.backendPrefix,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as any).auth.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        login: builder.mutation<LoginRes, { username: string; password: string }>({
            query: ({username, password}) => ({
                url: "/admins/login",
                method: "POST",
                body: {username, password},
            }),
        }),
        profile: builder.query<Admin, string>({
            query: (token: string) => ({
                url: "/admins/profile",
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useProfileQuery,
} = adminApi;
