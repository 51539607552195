import {FormEvent, useEffect, useState} from 'react';
import {useChargeUserMutation, useGetProductsQuery, User} from '../../store';

interface UserChargeProps {
    user: User;
}

export function UserCharge({user}: UserChargeProps) {
    const {id: userId} = user;
    const [chargeUser, {isLoading, error}] = useChargeUserMutation();
    const {data: products = []} = useGetProductsQuery();

    const [productName, setProductName] = useState('');

    useEffect(() => {
        if (products.length > 0) {
            setProductName(products[0]?.name || '');
        }
    }, [products]);

    const renderedProductNames = products.map(({name}) => {
        return (
            <option key={name} value={name}>
                {name}
            </option>
        );
    });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await chargeUser({userId, productName}).unwrap();
        } catch (err: any) {
            console.error(err?.message || 'Charging user failed');
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    <h3>Charge User Account</h3>
                </div>
            </div>
            <div className="row ">
                <div className="col">
                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {(error as any).data?.message || 'Charging user failed'}
                        </div>
                    )}

                    <form onSubmit={handleSubmit} id={`charge_user_${userId}_form`}>
                        <div className="mb-3">
                            <label htmlFor={`productSelect_${userId}`} className="form-label">
                                Select Product
                            </label>
                            <select
                                id={`productSelect_${userId}`}
                                className="form-select form-select-sm"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                            >
                                {renderedProductNames}
                            </select>
                        </div>

                        <div className="mb-3">
                            <button
                                type="submit"
                                className="btn btn-sm btn-success w-100"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                ) : (
                                    'Charge User'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
