import {Card, Dashboard} from '../components/Dashboard';
import {useAppSelector, useGetUsersQuery} from "../store";

export function DashboardPage() {
    const {data: users} = useGetUsersQuery();
    const admin = useAppSelector(state => state.auth.admin);

    const cards: Card[] = [];

    if (users) {
        cards.push(
            {
                title: <div>{users.length} <small className="text-muted">Users</small></div>,
                link: '/users',
                linkText: 'View All'
            },
        );
    }

    if (admin) {
        cards.unshift({
            title: <div>Profile</div>,
            link: '/profile',
            linkText: 'View Profile'
        });
    }

    return (
        <div>
            <div className="container-fluid">
                <Dashboard cards={cards}/>
            </div>
        </div>
    );
}
