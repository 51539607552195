import {FormEvent, useState} from 'react';
import {useChangePanelMutation, User} from '../../store';
import {CONFIG} from '../../config';

interface UserChangePanelProps {
    user: User;
}

export function UserChangePanel({user}: UserChangePanelProps) {
    const {id: userId, panelName} = user;
    const [changePanel, {isLoading, error}] = useChangePanelMutation();

    const [newPanelName, setNewPanelName] = useState(CONFIG.panelNames[0]);

    const renderedPanelNames = CONFIG.panelNames.map((name, index) => {
        return (
            <option key={index} value={name}>{name}</option>
        );
    });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await changePanel({userId, panelName: newPanelName}).unwrap();
        } catch (e: any) {
            console.error(e?.message || 'Changing user panel failed');
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    <h3>Change User Panel</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {(error as any).data?.message || 'Changing panel failed'}
                        </div>
                    )}

                    <form onSubmit={handleSubmit} id={`change_panel_user_${userId}_form`}>
                        <div className="mb-3">
                            <label htmlFor={`currentPanel_${userId}`} className="form-label">
                                Current Panel
                            </label>
                            <input
                                type="text"
                                id={`currentPanel_${userId}`}
                                className="form-control"
                                value={panelName}
                                readOnly
                                disabled
                            />
                        </div>

                        {/* Select new panel */}
                        <div className="mb-3">
                            <label htmlFor={`panelSelect_${userId}`} className="form-label">
                                Select New Panel
                            </label>
                            <select
                                id={`panelSelect_${userId}`}
                                className="form-select form-select-sm"
                                value={newPanelName}
                                onChange={(e) => setNewPanelName(e.target.value)}
                            >
                                {renderedPanelNames}
                            </select>
                        </div>

                        <div className="mb-3">
                            <button
                                type="submit"
                                className="btn btn-sm btn-success w-100"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    'Change Panel'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
